import {
    Problem,
    AgentAccount,
} from '@michel.freiha/ng-sdk';

export class StoreProfileFromApi {
    public static readonly type: string = '[Profile API] Store profile';
    constructor(public account: AgentAccount) { }
}

export class FailFromApi {
    public static readonly type: string = '[Profile API] Fail';
    constructor(public problem: Problem) { }
}

export class AccountInfo {
    public static readonly type: string = '[Profile API] Info';
    constructor() { }
}
