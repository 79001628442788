// tslint:disable: member-ordering

import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    AgentAccount,
    AgentsService,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    AccountInfo,
    FailFromApi,
    StoreProfileFromApi,
} from './profile.actions';

import {
    SignOut,
} from '../auth/auth.actions';
import { catchError, tap } from 'rxjs/operators';


export class ProfileStateModel {
    public account: AgentAccount;
    public problem: Problem;
}

const stateDefaults: ProfileStateModel = {
    account: undefined,
    problem: undefined,
};

@State<ProfileStateModel>({
    name: 'profile',
    defaults: stateDefaults,
})
export class ProfileState {

    constructor(
        private _agentService: AgentsService,
    ) { }

    @Selector()
    public static account(state: ProfileStateModel): AgentAccount { return state.account; }

    @Selector()
    public static displayName(state: ProfileStateModel): string { return state.account.profile.fullName; }

    @Selector()
    public static problem(state: ProfileStateModel): Problem { return state.problem; }

    @Action(SignOut)
    public reset(ctx: StateContext<ProfileStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(StoreProfileFromApi)
    public storeProfile(ctx: StateContext<ProfileStateModel>, { account }: StoreProfileFromApi): any {
        ctx.patchState({ account: account });
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<ProfileStateModel>, { problem }: FailFromApi): void {
        ctx.patchState({ problem: problem });
    }

   

    @Action(AccountInfo)
    public accountInfo(ctx: StateContext<ProfileStateModel>): any {
        return this._agentAccountInfo(ctx);
    }

    private _agentAccountInfo(ctx: StateContext<ProfileStateModel>): any {

        return this._agentService.loadAccountInfo().pipe(

            tap((accountInfo: AgentAccount) => {
                ctx.patchState({ account: accountInfo });
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi(problem));
            }),
        );
    }
}
