/* tslint:disable:member-ordering */

import * as faker from 'faker';

import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import {
  produce,
} from 'immer';

import {
  EMPTY,
  throwError,
} from 'rxjs';

import {
  catchError,
  finalize,
  mergeMap,
  tap,
} from 'rxjs/operators';

import {
  AgentsService,
  Field,
  IdvChecksPublic,
  IdvCreateChecksRequest,
  IdvDocuments,
  IdvVerificationReportsPublic,
  NotFoundProblem,
  Problem,
  SDKToken,
  UsersService,
  ValidationProblem,
  WalletsService,
  WalletTransaction,
  WalletTransactionCategory,
  WalletTransactionParticipant,
  WalletTransactionReceiver,
  WalletTransactions,
  WalletTransactionStatus,
  WalletTransactionTransferDetails,
  WalletTransactionType,
} from '@michel.freiha/ng-sdk';

import {
  SignOut,
} from '@nymos/auth';

import {
  NotificationCenter,
} from '../../notification/services/notification.service';

import {
  WalletActivities,
} from '../../models/wallet-activities.model';

import {
  WalletActivitiesBuilder,
} from '../../builders/wallet-activities.builder';

import {
  WalletTransferBuilder,
} from '../../builders/wallet-transfer.builder';

import {
  Texts,
} from '../../texts/texts';

import {
  ConfirmTransferFromTopupPage,
  CreateIdvCheck,
  FailFromApi,
  GetDocumentReport,
  GetIdvCheck,
  LoadReceiverFromTopupPage,
  LoadWalletActivitiesFromWalletActivitiesPage,
  RefreshWalletActivitiesFromWalletActivitiesPage,
  SimulateTransferFromTopupPage,
  UserIDVDocumentStatus,
  UserSDKToken,
} from './wallet-transactions.actions';

import {
  Notifications,
} from './wallet-transactions.notifications';
import { Injectable } from '@angular/core';
import { Notification } from '../../shared';


interface Transfer {
  transaction?: WalletTransaction;
  receiver?: WalletTransactionReceiver;
  details?: WalletTransactionTransferDetails;
}

export interface WalletTransactionsStateModel {
  ids: string[];
  items: { [id: string]: WalletTransaction };
  token:string;
  IdvDocumentStatus:string;
  checkId:string;
  checkIdvResult:string;
  after: string;
  hasMore: boolean;
  loading: boolean;
  transfers: {
    [id: string]: {
      transaction: WalletTransaction,
      receiver: WalletTransactionReceiver,
      details: WalletTransactionTransferDetails,
    },
  };
  transferring: boolean;
  idvTokenLoading:boolean;
  problem: Problem;
}

const stateDefaults: WalletTransactionsStateModel = {
  ids: [],
  items: {},
  token:undefined,
  IdvDocumentStatus:undefined,
  checkId:undefined,
  checkIdvResult:undefined,
  after: undefined,
  hasMore: undefined,
  loading: undefined,
  transfers: {},
  transferring: undefined,
  idvTokenLoading:undefined,
  problem: undefined,
};

@State<WalletTransactionsStateModel>({
  name: 'wallet_transactions',
  defaults: stateDefaults,
})
export class WalletTransactionsState {

  @Selector()
  public static activities(state: WalletTransactionsStateModel): WalletActivities[] {
    const result = new WalletActivitiesBuilder().withTransactions(state.ids.map((id) => state.items[id])).build();
    return result;
  }

  public static completed(id: string): any {
    return createSelector([WalletTransactionsState], (state: WalletTransactionsStateModel) => {
      return new WalletTransferBuilder()
        .withTransaction(state.items[id])
        .build();
    });
  }

  public static transfer(id: string): any {
    return createSelector([WalletTransactionsState], (state: WalletTransactionsStateModel) => {
      return new WalletTransferBuilder()
        .withTransaction(state.transfers[id].transaction)
        .withReceiver(state.transfers[id].receiver)
        .build();
    });
  }

  @Selector()
  public static problem(state: WalletTransactionsStateModel): Problem {
    return state.problem;
  }

  @Selector()
  public static loading(state: WalletTransactionsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static hasMore(state: WalletTransactionsStateModel): boolean {
    return state.hasMore;
  }

  @Selector()
  public static transferring(state: WalletTransactionsStateModel): boolean {
    return state.transferring;
  }

  @Selector()
  public static idvTokenLoading(state: WalletTransactionsStateModel): boolean {
    return state.idvTokenLoading;
  }


  constructor(
    private _nc: NotificationCenter,
    private _walletService: WalletsService,
    private _userService: UsersService
  ) { }

  @Action(SignOut)
  public reset(ctx: StateContext<WalletTransactionsStateModel>): any {
    ctx.setState(stateDefaults);
  }

  @Action(LoadReceiverFromTopupPage, { cancelUncompleted: true })
  public loadReceiver(
    ctx: StateContext<WalletTransactionsStateModel>,
    { id, mobile,currency }: LoadReceiverFromTopupPage,
  ): any {

    const transfer: Transfer = {};

    ctx.patchState({ transferring: true, transfers: {} });

    return this._walletService.loadTransactionReceiverByMobile(mobile, 'user',currency).pipe(

      tap((receiver: WalletTransactionReceiver) => {
        transfer.receiver = receiver;
        ctx.setState(produce((state) => {
          state.transfers[id] = transfer;
        }));
      }),

      catchError((problem) => {
        if (problem instanceof NotFoundProblem)
          this._dispatchMobileNotFoundProblem(ctx);

        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ transferring: false });
      }),
    );
  }

  @Action(SimulateTransferFromTopupPage, { cancelUncompleted: true })
  public simulateTransfer(
    ctx: StateContext<WalletTransactionsStateModel>,
    { id, mobile, details,currency }: SimulateTransferFromTopupPage,
  ): any {
    details.dryRun = true;

    const transfer: Transfer = {};
    transfer.details = details;

    ctx.patchState({ transferring: true, transfers: {} });

    return this._walletService.loadTransactionReceiverByMobile(mobile, 'user',currency).pipe(

      catchError((problem) => {
        if (problem instanceof NotFoundProblem)
          this._dispatchMobileNotFoundProblem(ctx);
        return EMPTY;
      }),

      mergeMap((receiver: WalletTransactionReceiver) => {
        transfer.receiver = receiver;
        return this._walletService.transferBetweenWallets(id,transfer.receiver.account.id,receiver.walletId, details);
      }),

      tap((transaction: WalletTransaction) => {
        transfer.transaction = transaction;
        ctx.setState(produce((state) => {
          state.transfers[transaction.id] = transfer;
        }));
      }),

      catchError((problem) => {
        this._dispatchTransferProblem(ctx, problem);
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ transferring: false });
      }),
    );
  }

  @Action(ConfirmTransferFromTopupPage)
  public confirmTransfer(
    ctx: StateContext<WalletTransactionsStateModel>,
    { id }: ConfirmTransferFromTopupPage,
  ): any {
    const transfer = ctx.getState().transfers[id];

    const details = new WalletTransactionTransferDetails({ ...transfer.details, dryRun: true });
    details.dryRun = false;

    ctx.patchState({ transferring: true });
    return this._walletService.transferBetweenWallets(id, transfer.receiver.account.id, transfer.receiver.walletId, details).pipe(

      tap((transaction: WalletTransaction) => {
        ctx.setState(produce((state) => {
          state.transfers = {};
          state.ids.push(transaction.id);
          state.items[transaction.id] = transaction;
        }));
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        ctx.patchState({ transferring: false });
      }),
    );

  }

  @Action(UserSDKToken, { cancelUncompleted: true })
  public getSDKToken(
    ctx: StateContext<WalletTransactionsStateModel>,
    { userId }: UserSDKToken,
  ): any {
    ctx.patchState({ idvTokenLoading: true });
    return this._userService.generateSdkToken(userId).pipe(
      tap((sdkToken: SDKToken) => {
        ctx.setState(produce((state) => {
          state.token = sdkToken.token;
        }));
      }),

      catchError((problem) => {
        if(problem.status==400 && problem.detail=="User hasn't initiated IDV verification"){
           this._nc.show(Notifications.SdkTokenFailure)
        }
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        ctx.patchState({ idvTokenLoading: false });
      }),
    );

  }

  @Action(CreateIdvCheck, { cancelUncompleted: true })
  public createIdvCheck(
    ctx: StateContext<WalletTransactionsStateModel>,
    { userId ,agentId, reportNames}: CreateIdvCheck,
  ): any {
    ctx.setState({ ...stateDefaults, loading: true });
      let payload = new IdvCreateChecksRequest();
          payload.agentId = agentId;
          payload.userId = userId;
          payload.reportNames =  reportNames
    return this._userService.createIdvCheck(payload).pipe(
      tap((createIdvCheckResult: IdvChecksPublic) => {
        ctx.setState(produce((state) => {
          state.checkId = createIdvCheckResult.id;
        }));
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );

  }

  @Action(GetIdvCheck, { cancelUncompleted: true })
  public getIdvCheck(
    ctx: StateContext<WalletTransactionsStateModel>,
    { userId, agentId, checkId, isAgent}: GetIdvCheck,
  ): any {
    ctx.setState({ ...stateDefaults, loading: true }); 
    return this._userService.getIdvChecks(checkId,userId,agentId,isAgent).pipe(
      tap((getIdvCheckResult: IdvChecksPublic[]) => {
        ctx.setState(produce((state) => {
          if(getIdvCheckResult[0].status=='completed' && getIdvCheckResult[0].reportIds.length>0){
             state.reportId = getIdvCheckResult[0].reportIds[0];
           }
        }));
      }),

      catchError((problem) => {
        this._nc.show(Notifications.Failure)
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );

  }

  @Action(GetDocumentReport, { cancelUncompleted: true })
  public getDocumentReport(
    ctx: StateContext<WalletTransactionsStateModel>,
    { reportId,userId}: GetDocumentReport,
  ): any {
    ctx.setState({ ...stateDefaults, loading: true }); 
    return this._userService.getUserIdvReportsById(userId,reportId).pipe(
      tap((getDocumentReportResult: IdvVerificationReportsPublic) => {
        ctx.setState(produce((state) => {
          if(getDocumentReportResult.result =='clear'){
            this._nc.show(Notifications.CheckIdvMatchResult)
            state.checkReportResult = getDocumentReportResult.result;
           }else{
            this._nc.show(Notifications.CheckIdvNotMatchResult)
           }
        }));
      }),

      catchError((problem) => {
        this._nc.show(Notifications.Failure)
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );

  }

  @Action(UserIDVDocumentStatus, { cancelUncompleted: true })
  public getUserIdvDocumentStatus(
    ctx: StateContext<WalletTransactionsStateModel>,
    { userId }: UserIDVDocumentStatus,
  ): any {


    return this._userService.getUserIdvDocuments(userId).pipe(
      tap((idvDocumentStatus: IdvDocuments) => {
        ctx.setState(produce((state) => {
          if(idvDocumentStatus.documents && idvDocumentStatus.documents.length>0){
            state.idvDocumentStatus = idvDocumentStatus;
              // let filterRejectedIdvDocument = idvDocumentStatus.documents.filter((status_reason_code)=>{return status_reason_code.statusReasonCode=='R0'})
              //   if(filterRejectedIdvDocument.length>0){
              //     this._nc.show(Notifications.IdvDocumentStatus)
              //     state.idvDocumentStatus = [];
              //   }else{
               // }
           }
           else if(idvDocumentStatus.documents && idvDocumentStatus.documents.length==0){
                this._nc.show(Notifications.IdvDocumentStatus)
                state.idvDocumentStatus = [];
            }
        }));
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        ctx.patchState({ transferring: false });
      }),
    );

  }

  @Action(LoadWalletActivitiesFromWalletActivitiesPage)
  public loadWalletActivities(ctx: StateContext<WalletTransactionsStateModel>): any {
    ctx.setState({ ...stateDefaults, loading: true });
    return this._loadWalletActivities(ctx).pipe(
      finalize(() => ctx.patchState({ loading: false })),
    );
  }

  @Action(RefreshWalletActivitiesFromWalletActivitiesPage)
  public refreshWalletActivities(ctx: StateContext<WalletTransactionsStateModel>): any {
    return this._loadWalletActivities(ctx);
  }

  @Action(FailFromApi)
  public fail(ctx: StateContext<WalletTransactionsStateModel>, { problem }: FailFromApi): void {
    ctx.patchState({ problem: problem });
  }

  private _loadWalletActivities(
    ctx: StateContext<WalletTransactionsStateModel>,
  ): any {
    const after = ctx.getState().after;
    return this._walletService.loadWalletTransactions(after,'DESC').pipe(
      tap((transactions: WalletTransactions) => {
        ctx.setState(produce((state) => {

          state.ids = transactions.data.map((transaction) => transaction.id);

          transactions.data.forEach((transaction) => {
            state.items[transaction.id] = transaction;
          });

          state.hasMore = transactions.hasMore;
        }));
      }),

      catchError((problem) => {
        // Let global handler catch it
        return throwError(problem);
      }),
    );
  }

  private _dispatchMobileNotFoundProblem(
    ctx: StateContext<WalletTransactionsStateModel>,
  ): any {
    const problem = new ValidationProblem({
      detail: Texts.Problems.AccountNotFound,
      fields: [new Field({ field: 'mobile', message: Texts.Problems.AccountNotFound })],
    });

    return ctx.dispatch(new FailFromApi(problem));
  }

  private _dispatchTransferProblem(
    ctx: StateContext<WalletTransactionsStateModel>,
    problem: Problem,
  ): any {
    const validation = new ValidationProblem({
      detail: problem.detail,
      fields: [new Field({ field: 'amount', message: problem.detail })],
    });

    return ctx.dispatch(new FailFromApi(validation));
  }

  private _mockB(): WalletTransactions {

    return new WalletTransactions({
      data: [this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA(), this._mockA()],
      // data: [],
      hasMore: false,
      version: '1',
    });
  }

  private _mockA(): WalletTransaction {

    const amount = +faker.finance.amount(20, 50);
    const senderFirstName = faker.name.firstName();
    const senderLastName = faker.name.lastName();
    const receiverFirstName = faker.name.firstName();
    const receiverLastName = faker.name.lastName();

    return new WalletTransaction({
      id: faker.random.uuid(),
      friendlyId: faker.random.alphaNumeric(10).toUpperCase(),
      accountId: faker.random.uuid(),
      walletId: faker.random.uuid(),
      description: 'Wallet transaction description id',
      currency: 'USD',
      amount: amount,
      totalFees: 0.1 * amount,
      totalAmount: 1.1 * amount,
      sender: new WalletTransactionParticipant({
        id: faker.random.uuid(),
        type: 'agent',
        displayName: `${senderFirstName} ${senderLastName}`,
        mobile: faker.phone.phoneNumber('+#########'),
        email: faker.internet.email(senderFirstName, senderLastName).toLowerCase(),
      }),
      receiver: new WalletTransactionParticipant({
        id: faker.random.uuid(),
        type: 'agent',
        displayName: `${receiverFirstName} ${receiverLastName}`,
        mobile: faker.phone.phoneNumber('+#########'),
        email: faker.internet.email(receiverFirstName, receiverLastName).toLowerCase(),
      }),
      type: faker.random.arrayElement(Object.values(WalletTransactionType)),
      category: faker.random.arrayElement([
        WalletTransactionCategory.WalletCredit,
        WalletTransactionCategory.WalletDebit,
        // WalletTransactionCategory.CardIssuance,
        // WalletTransactionCategory.CardReload,
        WalletTransactionCategory.FundSend,
        WalletTransactionCategory.FundReceive,
      ]),
      status: faker.random.arrayElement(Object.values(WalletTransactionStatus)),
      created: new Date(),
      modified: new Date(),
    });
  }
}
