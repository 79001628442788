// tslint:disable:variable-name

export type StringFn = (str: string) => string;

export namespace Texts {

  export class Actions {
    public static readonly Failure: string = `Unable to process the request. Please give it another try!`;
    public static readonly SdkTokenFailure: string = `User hasn't initiated IDV verification`;
    public static readonly IdvDocumentStatus: string = `Document not uploaded by this user via IDV`;
    public static readonly CheckIdvNotMatchResult: string =  `Identity Mismatched`;
    public static readonly CheckIdvMatchResult: string =  `Identity Match`;
  }

  export class Problems {
    public static readonly AccountNotFound: string = `Account is invalid or not yet approved`;
  }
}
